<template>
  <div>
    <el-button type="success" :disabled="$store.state.userRoles !== 'ADMIN'" @click="showAddUserModal" icon="el-icon-user"
      round>Add User</el-button>
    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog :title="`Add Admin User`" :visible.sync="addUserModal" :destroy-on-close="true"
            :before-close="handleClose" style="text-align: left">
            <div v-loading="submitting">
              <el-form :model="userForm" :rules="rules" label-position="top" ref="userForm" class="demo-userForm2">
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="First Name" prop="first_name">
                      <el-input v-model="userForm.first_name" @keyup.enter.native="addUser('userForm')"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item label="Last Name" prop="last_name">
                      <el-input v-model="userForm.last_name" @keyup.enter.native="addUser('userForm')"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Phone" prop="phone">
                      <el-input v-model="userForm.phone" @keyup.enter.native="addUser('userForm')"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item label="Email" prop="email">
                      <el-input v-model="userForm.email" @keyup.enter.native="addUser('userForm')"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Username" prop="username">
                      <el-input v-model="userForm.username" @keyup.enter.native="addUser('userForm')"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item label="Password" prop="password">
                      <el-input v-model="userForm.password" @keyup.enter.native="addUser('userForm')"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Choose User Role" prop="role">
                      <el-select v-model="userForm.role" @keyup.enter.native="addUser('userForm')" placeholder="Select" class="w-100">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item label="Choose Gender" prop="gender">
                      <el-select v-model="userForm.gender" @keyup.enter.native="addUser('userForm')" placeholder="Select" class="w-100">
                        <el-option v-for="gender in genders" :key="gender.id" :label="gender.title" :value="gender.title">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="User Photo">
                      <el-upload class="upload-demo" drag action="''" :on-change="handleUserPhotoPreview"
                        :on-remove="handleUserPhotoRemove" :file-list="userPhotoFileLists" :auto-upload="false"
                        :multiple="false">
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                            :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                          <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                            :stroke-width="4"></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                              imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                            }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button type="success" round @click="addUser('userForm')">Add Admin
                    User</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>Design by <a href="https://ovalspace.co"
                      style="color: black;"><strong>Ovalspace</strong></a></small>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      bookings: [],
      users: [],
      userEditId: "",
      selectedUserPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      userPhotoFileLists: [],
      genders: [
        { id: 1, title: "Male" },
        { id: 1, title: "Female" },
      ],

      options: [
        {
          value: "AGENT",
          label: "Agent",
        },
        {
          value: "ADMIN",
          label: "Admin",
        },
      ],

      addUserModal: false,
      editUserModal: false,
      years: "",
      year_numbers: [],
      value: "",
      loadingError: false,
      submitting: false,
      course: "",
      userForm: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        username: "",
        role: "",
        gender: "",
        password: "",
      },

      rules: {
        first_name: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Last Name is required", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "Phone is required", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
        username: [
          {
            required: true,
            message: "Username is required",
            trigger: "change",
          },
        ],
        gender: [
          {
            required: true,
            message: "Gender is required",
            trigger: "change",
          },
        ],
        role: [
          {
            required: true,
            message: "User Role is required",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "change",
          },
        ],
      },
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    handleUserPhotoPreview(file) {
      console.log(file.raw);
      this.userPhotoFileLists = [file];
      this.selectedUserPhotoFile = file.raw;
    },
    handleUserPhotoRemove() {
      this.selectedUserPhotoFile = null;
    },

    showAddUserModal() {
      this.addUserModal = true;
      this.userForm.first_name = "";
      this.userForm.last_name = "";
      this.userForm.phone = "";
      this.userForm.email = "";
      this.userForm.username = "";
      this.userForm.role = "";
      this.userForm.gender = "";
      this.userForm.password = "";
    },

    async addUser(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("first_name", this.userForm.first_name);
            formData.append("last_name", this.userForm.last_name);
            formData.append("username", this.userForm.username);
            formData.append("email", this.userForm.email);
            formData.append("phone", this.userForm.phone);
            formData.append("profile_picture", this.selectedUserPhotoFile);
            formData.append("role", this.userForm.role);
            formData.append("gender", this.userForm.gender);
            formData.append("password", this.userForm.password);
            formData.append("created_by", this.$store.state.userId);
            let response = await this.$http.post(`users`, formData, {
              onUploadProgress: (progressEvent) => {
                this.imageUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            });
            if (
              response.data.success &&
              response.data.message == "ACCOUNT_CREATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "User added successfully",
                type: "success",
              });
              this.addUserModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to add user",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style></style>